<template>
  <div
    class="full-news"
    v-if="singleNews"
  >
    <div class="container">
      <div class="news__nav">
        <locale-router-link
          to=""
          class="crumbs-link main-page-link"
        >
          {{ $t("home") }}
        </locale-router-link>
        <locale-router-link
          to="/news"
          class="crumbs-link"
        >
          {{ $t("news.title") }}
        </locale-router-link>
        <a class="crumbs-link recipes-link"> {{ singleNews.attributes.header }}</a>
      </div>

      <div class="full-news__header">
        <div class="full-news__header-left">
          <h1 class="full-news__title">{{ singleNews.attributes.header }}</h1>
          <div class="full-news__info">
            <p class="full-news__date">{{ getDate() }}</p>
            <div class="full-news__views">
              <img src="@/assets/icons/eye.svg" />&nbsp;{{ singleNews.attributes.hits }}
            </div>
          </div>
        </div>
      </div>

      <div
        class="full-news__main"
        v-if="newsData"
      >
        <news-block
          class="full-news__content"
          :item="singleNews"
        />
        <news-aside-list
          class="full-news__aside"
          :items="newsData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import NewsAsideList from "@/components/news/NewsAsideList.vue"
import NewsBlock from "@/components/news/NewsBlock.vue"

import { getArticles } from "@/api/articles"
import { getNewsById } from "@/api/news"

export default {
  name: "FullNews",

  components: {
    NewsBlock,
    NewsAsideList,
  },

  data() {
    return {
      newsData: null,
      singleNews: null,
      localMonth: {
        января: "қаңтар",
        февраля: "ақпан",
        марта: "наурыз",
        апреля: "сәуір",
        мая: "мамыр",
        июня: "маусым",
        июля: "шілде",
        августа: "тамыз",
        сентября: "қыркүйек",
        октября: "қазан",
        ноября: "қараша",
      },
    }
  },
  watch: {
    "$attrs.id": {
      handler() {
        getNewsById(this.$attrs.id)
          .then((res) => {
            this.singleNews = res
          })
          .then(() => {
            this.loadAllNews()
          })
      },
    },
  },

  created() {
    getNewsById(this.$attrs.id)
      .then((res) => {
        this.singleNews = res
        if (this.$i18n.locale === "kz") {
          // getNewsById(res.)
          // .then((res) => {
          //   this.singleNews = res
          // })
          // .then(() => {
          //   this.loadAllNews()
          // })
        }
      })
      .then(() => {
        this.loadAllNews()
      })
  },

  methods: {
    getDate() {
      const dateFromStr = new Date(this.singleNews.attributes.publishedAt)
      let dateRU = new Intl.DateTimeFormat("ru-Ru", {
        year: "numeric",
        month: "long",
        day: "numeric",
      }).format(dateFromStr)
      let date = dateRU.split(" ")
      let dateKZ = date[0] + " " + this.localMonth[date[1]] + " " + date[2]
      if (this.$i18n.locale === "kz") {
        return dateKZ
      } else {
        return dateRU
      }
    },
    async loadAllNews() {
      const response = await getArticles(1, 500, "news")
      this.newsData = response.data

      this.newsData.forEach((element, index) => {
        if (element.id === this.singleNews.id) {
          this.newsData.splice(index, 1)
        }
      })
      // this.newsData.splice(this.newsData.indexOf(this.newsData.find(()=>{return this.singleNews.id})), 1)
    },
  },

  computed: {},
}
</script>

<style
  lang="scss"
  scoped
></style>
