<template>
  <div>
    <div class="full-news__aside-items">
      <news-card v-for="item in items"  :key="item.id" :item="item"  />
    </div>
  </div>
</template>

<script>
import NewsCard from "@/components/news/NewsCard.vue"

export default {
  name: "NewsAsideList",

  components: {
    NewsCard,
  },

  props: {
    items: {
      // type: Array,
      required: true,
    },
  },
}
</script>

<style
  lang="scss"
  scoped
></style>
