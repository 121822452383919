<template>
  <div
    class="full-news__content"
    v-if="item"
  >
    <img
      class="full-news__content-img"
      :src="getNewsImg()"
      alt=""
    />
    <vue-markdown
      class="full-news__content-text"
      :source="item.attributes.text"
    >
      {{ item.attributes.text }}
    </vue-markdown>
    <div class="full-news__content-share">
      <a
        class="share-actions-list__item"
        :href="`http://www.odnoklassniki.ru/dk?st.cmd=addShare&st.s=1&st._surl=${getShareUrl}`"
        target="_blank"
        ><img src="@/assets/icons/share/ok.svg"
      /></a>
      <a
        class="share-actions-list__item"
        :href="'https://vk.com/share.php?url=' + getShareUrl"
        target="_blank"
        ><img src="@/assets/icons/share/vk.svg"
      /></a>
      <a
        class="share-actions-list__item"
        :href="`https://www.facebook.com/sharer/sharer.php?u=${getShareUrl}`"
        target="_blank"
        ><img src="@/assets/icons/share/fb.svg"
      /></a>
      <a
        class="share-actions-list__item"
        :href="`https://wa.me/?text=${getShareUrl}`"
        data-action="share/whatsapp/share"
        target="_blank"
        ><img src="@/assets/icons/share/whatsapp.svg"
      /></a>
      <a
        class="share-actions-list__item"
        target="_blank"
        :href="'https://t.me/share/url?url=' + getShareUrl + '&text=Поделится с ссылкой'"
        ><img src="@/assets/icons/share/telegram.svg"
      /></a>
    </div>
  </div>
</template>

<script>
import VueMarkdown from "vue-markdown"

export default {
  name: "NewsBlock",
  components: {
    VueMarkdown,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    getShareUrl() {
      return process.env.VUE_APP_API_URL + this.$route.fullPath
    }
  },
  methods: {
    getNewsImg() {
      const imgUrl = this.item?.attributes?.image?.data?.attributes?.url
      if (imgUrl) {
        return process.env.VUE_APP_API_URL + imgUrl
      }
      return require("@/" + "assets/img/icons/placeholder.svg")
    },
  },
}
</script>

<style></style>
